import React from 'react'
import {compose, setDisplayName, withHandlers, withStateHandlers} from 'recompose'
import {connect} from 'react-redux'

import {privacyAction, cookiesAction, termsAction} from '../../../../actions/privacyPolicy'
import {getLocaleString} from '../../../../locale'
import cancelIcon from '../../../../assets/icons/cancel.svg'

import {Link} from "react-router-dom";
import './CookiesPolicy.css'
import {always, cond, equals, T} from "ramda";

const mapStateToProps = (state) => ({
  language: state.userData.language
})

const mapDispatchToProps = (dispatch) => ({
  toggleActivePrivacy: () => dispatch(privacyAction()),
  toggleActiveCookies: () => dispatch(cookiesAction()),
  toggleActiveTerms: () => dispatch(termsAction()),
})

const state = {
  showPreferences: false,
  checkboxes: {
    necessary: {
      checked: true,
      disabled: true
    },
    analytics: {
      checked: false
    },
    functionality: {
      checked: false
    },
    targeting: {
      checked: false
    }
  }
}

const stateHandlers = {
  togglePreferences: ({showPreferences}) => () => ({
    showPreferences: !showPreferences
  }),
  toggleCheckbox: ({checkboxes}) => (name) => {
    checkboxes[name].checked = !checkboxes[name].checked
    return {checkboxes}
  }
}

const handlers = {
  setCookie: ({setCookiesPolicy, checkboxes}) => (flag) => {
    let status = 'accept'
    if (!flag) {
      status = checkboxes.analytics.checked && checkboxes.functionality.checked ? 'accept' : 'denied'
    }
    setCookiesPolicy(status)
  }
}

const enhance = compose(
  setDisplayName('CookiesPolicy'),
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(state, stateHandlers),
  withHandlers(handlers)
)

const CookieText = ({language, togglePreferences, setCookie}) => (
  <div className="CookiesPolicy__text">
    {getLocaleString('cookie.text_before')}
    <span className="btn-in-text" onClick={togglePreferences}>{getLocaleString('cookie.preferences')}</span>
    {getLocaleString('cookie.text_between')}
    <span className="btn-in-text" onClick={() => setCookie(true)}>{getLocaleString('cookie.ok')}</span>
    {getLocaleString('cookie.text_after')}
    <Link className="btn-in-text" to={`/${language}/cookies`}>{getLocaleString('cookie.cookie_notice')}</Link>
  </div>
)

const CookieButtons = ({setCookie, showPreferences, togglePreferences}) => (
  <div className="CookiesPolicy__button">
    <button className="Button Button__cookie-accept" onClick={() => setCookie(true)}>{getLocaleString('cookie.accept')}</button>
    <button className={`Button__cookie-secondary${showPreferences?'':' show'}`} onClick={togglePreferences}>{getLocaleString('cookie.customise')}</button>
    <button className={`Button__cookie-secondary${showPreferences?' show':''}`} onClick={() => setCookie(false)}>{getLocaleString('cookie.save')}</button>
  </div>
)

const PreferencesList = ({showPreferences, checkboxes, toggleCheckbox}) => cond([
  [equals(true), always(
    <div className="preferences-list">
      {Object.keys(checkboxes).map((name, i) =>
        <PreferencesItem key={i} checkbox={checkboxes[name]} name={name} toggleCheckbox={toggleCheckbox}/>)}
    </div>
  )],
  [T, always(null)]
])(showPreferences)

const PreferencesItem = ({checkbox: {checked, disabled}, name, toggleCheckbox}) => (
  <label className="preferences-list-item">
    <input type="checkbox" name={name} checked={checked} disabled={disabled} onChange={() => toggleCheckbox(name)}/>
    <span className="checkbox-icon">
      <svg className="icon-arrow" viewBox="0 0 32 32">
        <path fill="currentColor" d="M14.416 24.464l-13.759-13.76c-0.876-0.875-0.876-2.294 0-3.169s2.294-0.875 3.169 0l12.175 12.175 12.174-12.175c0.875-0.875 2.294-0.875 3.169 0s0.875 2.294 0 3.169l-13.759 13.76c-0.438 0.437-1.011 0.656-1.584 0.656s-1.147-0.219-1.584-0.656z"></path>
      </svg>
    </span>
    <span className="text">{getLocaleString(`cookie.${name}`)}</span>
  </label>
)

const CookieClose = ({setCookie}) => (
<div className="CookiesPolicy__cancel" onClick={() => setCookie(false)} >
  <img src={cancelIcon} alt="cancel"/>
</div>
)

const View = ({setCookie, language, checkboxes, showPreferences, togglePreferences,
                toggleCheckbox}) => (
<div className="CookiesPolicy__wrapper">
  <CookieClose setCookie={setCookie}/>
  <div className="cookie-container">
    <CookieText language={language} togglePreferences={togglePreferences} setCookie={setCookie}/>
    <CookieButtons setCookie={setCookie} showPreferences={showPreferences} togglePreferences={togglePreferences}/>
    <PreferencesList checkboxes={checkboxes} showPreferences={showPreferences} toggleCheckbox={toggleCheckbox}/>
  </div>
</div>
)

export const CookiesPolicy = enhance(View)
