export const about = () => (`
  <h1> PocketBook 云服务描述</h1>
  <section>
    <p> 想象一个巨大的图书馆，它可以容纳成千上万的书籍，次序井然，并在空白处可以记笔记。如今得益于最新技术，这种图书库得以实现，无论您身在何处或使用哪种设备，图书库都始终可用。</p>
   <p><span>PocketBook 云服务</span> 是一项跨平台服务，它将您的电子阅读器，Android或iOS系统智能手机，平板电脑和PC设备链接到一个生态系统中。它能够同步电子书文件，阅读位置和个人设置。换句话说，你可以在电子阅读器上开始阅读一本书，然后继续在你的手机上阅读，然后再次回到PocketBook阅读，而且不会浪费时间查找上次阅读的页面，改变字体或任何其他设置。<span>PocketBook 在线云服务</span> 旨在为来自世界各地的热情书迷提供最优质的阅读体验。</p>
    <p> 我们的设备采用最新一代的E Ink技术，使电子阅读器的屏幕尽可能贴近纸质书籍，保证您的眼睛安全。当然，即使是现代智能手机、平板电脑和笔记本电脑也不能吹嘘能做到这一点，所以这也引起了疑问: 为什么有人在TFT显示器上阅读？事实是，小巧轻盈的电子阅读器并不总是在身边，例如，您在公交车上或在咖啡馆等朋友。在很多情况下，您花10分钟的时间就可以投入到一本引人入胜的小说中，不能因为没有电子阅读器而阻止您阅读。</p>
    <p> 由于您的电子阅读器上预装了 <span>PocketBook 云服务应用程序，</span> 个人图书库更具移动性和交互性。您可以在与 <span>PocketBook 云服务连接的任何设备上阅读，已阅读的电子书 </span> 也可以在智能手机，平板电脑或PC上阅读。</p>
    <p> 要在PC上使用云服务，请转至网页Cloud.pocketbook.digital。该服务已经预装在PocketBook电子阅读器中，并且非常容易添加入您其他的移动设备。您需要做的就是从Play Market或App Store上传PocketBook Reader应用程序，然后在本地在线书店(BookLand，Buchmedia，KNV，Umbreit，Legimi等)上注册。确保您在所有设备<span>包括: reader，iPhone，Android智能手机和平板电脑 上使用PocketBook云服务时，</span>使用 相同的登录数据，因为它们将自动接收具有同步文件，阅读位置和设置的图书库。</p>
  </section>
`)
export const advantages = () => (`
  <h1> PocketBook 云服务优势</h1>
  <section>
    <p> PocketBook发展的主要动力就是通过不断前进和发展新思想，将电子阅读提高到一个全新的水平。这种创新的一个很好的例子是通过各种设备公开访问您的书籍。<span>PocketBook云服务使读者的图书库 </span> 触手可得，而且不同设备之间的文件同步快速而直接。</p>
    <p><span> 电子书云存储 </span> 为读者提供了众多好处: 无论您是拿着电子阅读器、智能手机阅读，还是坐在电脑或平板电脑旁阅读，您的书始终与您在一起。例如，使用 <span> 在线云服务，读者</span> 可以在几秒钟内同步手机或平板电脑的内容。您需要做的就是将设备连接到云服务(此处有详细的说明)。</p>
    <p> 要想从PocketBook 云服务中受益，您需要了解它的工作原理。云服务的主要任务就是实现电子阅读器与电话或PC同步。您将获得2 GB的文档存储空间，这些空间可同时在所有连接的设备中使用。但这还不是全部，您的设置和阅读位置也会同您的图书库一起保存。想象一下它有多方便: 无论您使用的是哪种设备，您的书都将始终在最近阅读的页面上打开。</p>
    <p><span>PocketBook 云图书库</span> 方便您随时随地阅读。尽管电子墨水阅读器比传统的TFT显示器安全得多，但多数时候，电子阅读器都不在身边。因此 <span> 电子阅读器云 </span> 服务应运而生。它使您可以在有空的时候继续在手机上阅读未完成的小说。</p>
   <p> 为了照顾读者，PocketBook创建了一个完整的生态系统，它结合了能在Android，iOS，Windows和Linux系统上运行的设备。这个小工具的优点之一就在于它是具有同步功能的跨平台电子阅读器。您不必做出选择-因为PocketBook云服务结合了所有流行的小工具。</p>
    <p> 我们相信，唯有开放才能成就未来。因此，不同设备和平台之间实现同步非常必要。我们的产品不仅仅是一个电子阅读器，它是一个真正的图书库，它永远伴随着你。</p>
    <h2> PocketBook 云服务由以下公司支持: </h2>
    <p>-从Touch Lux 2开始的PocketBook Touch Lux系列的电子阅读器，</p>
    <p>-从Touch HD开始的PocketBook Touch HD系列的电子阅读器，</p>
    <p>-从Aqua 2开始的PocketBook Aqua系列的电子阅读器，</p>
    <p>-从InkPad开始的PocketBookInkPad系列的电子阅读器。</p>
  </section>
`)
export const howToUse = () => (`
  <h1> 如何使用PocketBook云服务 </h1>
  <section>
  <p>PocketBook 云服务是一个虚拟图书库，您可以从任何电子设备进入访问。为了您的阅读舒适，电子书，阅读位置和个人设置都可以同步。无论使用哪种设备: 电子阅读器，智能手机，平板电脑或PC，您都将始终进入上次阅读的页面。该服务做到对每个用户尽可能简单明了。了解更多信息并了解 <span> 如何使用PocketBook 云服务，用于移动设备的阅读器</span> 应用程序以及用于pc的web版本。</p>
    <h2> 如何访问PocketBook云服务 </h2>
    <p> 使用云服务的第一步是在本地在线书店(BookLand，Buchmedia，KNV，umbrit等)中注册，创建一个个人帐户，请记住您的用户名和密码-您需要它们来激活您的 <span>PocketBook云帐户。</span></p>
    <h2><span> 如何登录PocketBook云阅读 </span> 平台？</h2>
    <p> 下一步非常简单: 您需要在电子阅读器上输入本地书店登录数据，以将其连接到云服务。该操作仅需一分钟，请确保在登录之前已连接到wi-fi。</p>
    <p> 要同步智能手机或平板电脑， <span>开启 PocketBook 云阅读</span>，您需要下载一个特定的应用程序-PocketBook阅读器，该应用程序对Android和iOS系统免费。在本地书店输入您的登录名和密码，以将设备连接到云服务。</p>
    <p> 如果要在PC上阅读，您需要在任意浏览器中打开PocketBook 云服务官方网站，并输入上述登录数据。</p>
    <p> 但是，值得一提的是，用户不仅仅与当地书店的书籍联系在一起。您可以轻松添加需在其他书店购买的PocketBook云图书。如要上传，您需要在计算机中点开网站cloud.pocketbook.digital，然后单击主页上的“上传新书”按钮，然后在弹出窗口中选择要下载的文件。这本书不仅会出现在云服务的网络版本中，还会出现在电子阅读器的相应部分中。</p>
    <p> 除此之外，还为每个用户提供了2 GB的可用空间来存储电子图书库，这从来没有像我们的跨平台服务那样具有移动性。要了解更多信息，请访问我们的官方YouTube频道，并观看使用云服务的视频手册。</p>
  </section>
`)

const seo = {
  about: about,
  advantages: advantages,
  howToUse: howToUse
}
export default seo;