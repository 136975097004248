const books = {
  uploadBook: '上傳新書',
  sortBy: '排序',
  recent: '添加日期',
  byRecent: '按日期添加',
  byRecentTitle: '最近阅读',
  byTitle: '按标题',
  byAuthor: '按作者',
  byProgress: '按进度',
  title: '标题',
  titleInList: '标题列表',
  author: '作者',
  authorInList: '作者列表',
  readingProgress: '阅读进度',
  progress: '进度',
  lastRead: '上次阅读',
  dateAdded: '添加日期',
  format: '格式',
  size: '尺寸',
  collectionNotEmpty: '收藏夹名称不能为空',
  collectionNameTaken: '此收藏夹名称已存在',
  book: '书',
  books: '书籍',
  e_book: '电子书',
  e_books: '电子书',
  selected: '选择',
  selected_more_than_one: '选择多本',
  delete: '删除',
  backTo: '返回',
  noBooksInCollection: '尚无收藏书籍。',
  addBooksToCatalogue: '要添加新书籍，请转至目录',
  allBooks: '所有书籍',
  sureDeleteBook: '您确定要删除所选书籍吗？',
  sureDelete: '您确定要删除:',
  listen: '听',
  read: '阅读',
  details: '详细信息',
  addToCollection: '添加到收藏',
  addToCollectionInDropdown: '添加到收藏',
  addBooks: '添加书籍',
  newCollection: '新收藏',
  removeFavorites: '从收藏夹中删除',
  addFavorites: '添加到我喜欢列表',
  addFavoritesInDropdown: '添加到我喜欢列表',
  download: '下载',
  removeFromCollection: '从收藏夹中删除',
  bookFormat: '格式:',
  bookSize: '尺寸:',
  audiobook: '有声读物',
  audiobooks: '有声读物',
  audioBookTitle: '书名: ',
  audioBookYear: '出版年份: ',
  audioBookAuthor: '作者: ',
  audioBookSize: '尺寸: ',
  audioBookFavorite: '在我喜欢列表中: ',
  audioBookPurchased: '购买: ',
  audioBookGenres: '流派: ',
  searchAuthors: '按作者搜索',
  anonymousAuthor: '匿名作者',
  authors: '作者',
  addBook: '添加书籍 ',
  by: ' 按 ',
  toCollection: ' 加入收藏夹',
  chooseCollection: '选择收藏夹:',
  confirm: '确认',
  collection: '收藏',
  collections: '收藏',
  collectionsLinkBack: '收藏',
  collectionName: '收藏夹名称',
  confirmNewCollection: '确认新收藏',
  createNewCollection: '创建新收藏夹' ,
  uploading: '上传: ',
  completed: '已完成',
  seeDetails: '查看详细信息',
  getBook: '获取书籍',
  readRateLogo: 'readrate 图标',
  seeAllBooks: '查看所有书籍',
  newThisWeek: '本周新增',
  uploadFailed: '上传失败',
  uploadSuccess: '上传成功',
  uplodeToPBCloud: '上传到PB云端',
  done: '完成',
  addMoreFiles: '添加更多文件',
  from: '来自',
  filesLeft: '文件左',
  unknownAuthor: '未知作者',
  unknown: '未知',
  purchased: '购买',
  favorites: '我喜欢列表',
  series: '连载',
  genres: '流派',
  genresLinkBack: '流派',
  formats: '格式',
  formatsLinkBack: '格式',
  bookMoreThanFive: '书籍',
  collectionMoreThanFive: '收藏夹',
  byAuthorTitle: '作者书籍',
  emptyAudioBook: '这是您在商店购买的有声读物',

  isbn: '书号',
  annotation: '注释',
  publisher: '出版社',


  chaptersTitle: '章节标题',
  bookInfoTitle: '本书简介',
  bookmarksTitle: '书签',
  addBookmark: '添加书签',
  add: '添加',
  cancel: '取消',
  noteOptional: '注释(可选)'
}
export default books;