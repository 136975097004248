const settings = {
  delete: '删除',
  eMail: '电子邮件',
  vendor: '供应商',
  UUID: '通用唯一标识符',
  password: '密码',
  makeDefault: '默认设置',
  defaultID: '默认id',
  addAdobeID: '添加Adobe id',
  youAreSigned: '您已登录到您的ReadRate帐户',
  logOut: '注销',
  linkAccount: '链接您的帐户',
  used: '使用',
  outOf: '输出',
  changeAccount: '更改您的帐户',
  spaceInAccount: '帐户空间',
  settings: '设置',
  getMoreSpace: '获得更多空间',
  loginLength: '登录账号必须超过6个字符',
  loginNotEmpty: '登录账号不能为空',
  passLength: '密码必须长于8个字符',
  selectVendor: '请从列表中选择一个供应商',
  passNotEmpty: '密码不能为空',
  chooseVendorAdobe: '选择Adobe id的供应商',
  login: '登录',
  provideLogin: '提供登录账号',
  providePassword: '提供密码',
  continue: '继续',
  currency: '美元',
  month: '月份',
  moreSpace: '更大空间，更多书籍带给您更多的欢乐',
  perfectPlan: '为您找到完美的计划-保证100%满意',
  cancel: '取消',
  confirm: '确认',
  showPassword: '显示密码',
  secured: '安全',
  addVendorFormFailureMessage: 'Adobe授权失败。登录账号或密码无效',
  add: '添加',
  freshTheme: '最新主题',
  classicTheme: '经典主题',

  emailLength: '邮箱长度',
  getPassword: '获取密码',
  emailNotEmpty: '电子邮箱不能为空',
  resetPasswordFormText: '请进入邮箱接收链接以查看密码。',
  passwordFormSuccessMessage: '电子邮件已成功发送',
  passwordFormFailedMessage: '发送电子邮件出错',
  showPasswordTitle: '来自内部DRM帐户的密码:',
  'Invalid hash': '无效链接',
  'Link expired': '链接已失效'
}
export default settings;