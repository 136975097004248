const account = {
  name: '名称',
  surname: '姓氏',
  loginEmail: '登录电子邮件箱',
  bookOfWeek: '每周选书',
  personRecom: '推荐阅读',
  bestsellers: '畅销书',
  newThisWeek: '本周新书',
  readReateNews: 'readrate新闻',
  readRateDisc: 'readrate讨论',
  nameNotEmpty: '名字不能为空',
  nameIncorect: '名字不正确',
  emailNotEmpty:'电子邮箱不能为空',
  emailIncorect: '电子邮箱不正确',
  surnameNotEmpty: '姓氏不能为空',
  surnameIncorect: '姓氏不正确',
  resetGoToStore: '要重置密码，请转至您的商店:',
  resetPassword: '重置密码',
  dashboardWidgets: '仪表板小工具:',
  saveChanges: '保存更改',
  changeLanguage: '更改语言',
  en: '英语',
  ru: '俄语',
  de: '德语',
  zh: '中國人',
  findStore: '没有帐户吗？'
}
export default account;