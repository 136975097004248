const sidebar = {
  eBooks: '电子书',
  audioBooks: '有声读物',
  purchased: '购买',
  recentlyRead: '最近阅读',
  favorites: '我喜欢列表',
  authors: '作者',
  series: '系列',
  genres: '流派',
  formats: '格式',
  collections: '收藏夹',
  settings: '设置',
  yourAccount: '你的账户',
  adobeID: 'adobeID',
  readRate: 'ReadRate',
  plan: '计划',
  dashboard: '仪表板',
  books: '书籍',
  getMore: '在我的商店里得到更多',
  getApp: '获取应用程序',

  interface: '接口',

  about_btn: '服务描述',
  advantages_btn: '优势',
  how_to_use_btn: '使用指南',
  backToDashboard: '返回仪表板',
  backToLogin: ' 返回登录',
  aboutUs: '关于我们'
}
export default sidebar;