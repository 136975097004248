const cookie = {
  text_before: "与许多公司一样，PocketBook使用cookie技术来增强您的用户体验，出于分析和营销目的，在运行本网站和第三方网站时为您提供相关优惠，提供您最感兴趣的事物。PocketBook尊重您的隐私权，我们诚挚邀请您花一点时间体验",
  preferences: "管理Cookie首选项",
  text_between: "请注意，始终启用严格必要的cookie。如果您对所有cookie文件的使用感到满意，只需单击",
  ok: "Ok",
  text_after: "在此弹窗中要了解更多有关cookie技术，其好处以及在Pocketbook中如何使用它，请转至",
  cookie_notice: "Cookie通知。",
  accept: "接受所有cookie",
  customise: "自定义cookie",
  save: "保存自定义首选项",
  necessary: "严格必要的cookie: 这些是提供您特别要求的服务和功能所需的cookie。我们可能会使用cookie和跟踪技术进行系统管理和/或允许您使用购物车功能。",
  analytics: "与分析和性能相关的cookie: 我们可能会使用cookie来评估我们服务的性能，包括我们分析实践中通过cookie服务改进向您推荐的内容。",
  functionality: "与功能相关的cookie: 我们可能会使用cookie来判断，例如，您以前是否访问过该服务，或者您是否是新访问者，并帮助我们确定您可能最感兴趣的功能。",
  targeting: "与定位相关的cookie: 我们可能会使用cookie来发送您对我们服务感兴趣的内容，包括广告。"
}
export default cookie;