const search = {
  noItems: '未找到数据',
  recentlyRead: '最近阅读',
  collections: '收藏夹',
  authors: '作者',
  books: '书籍',
  audioBooks: '有声读物',
  searchIcon: '搜索图标',
  canselSearch: '取消搜索图标',
  searchByTitleAuthor: '按书名或作者搜索',

  notFound: '未发现'
}
export default search;