import ecoSystem from '../../assets/images/eco_system.png'
import {SERVER} from "../../constants";

export const sectionArticle1 = () => (`
  <div class="step">
      <h2>1.PocketBook云服务在其生态系统中的作用 </h2>
      <img class="eco-system" src="`+ecoSystem+`"/>
  </div>
  <div class="step">
                    <h2>2.将电子书店连接到PB云端的说明 </h2>
                    <p class="sub-title"> 连接云端的方法: </p>
                    <ol>
                        <li> 授权Api(get_auth_options)<a target="_blank" href="https://drive.google.com/open?id=1He1vFdyjB69FGvWZhWVnpSo0tymV6f9e">https://drive.google.com/open?id=1He1vFdyjB69FGvWZhWVnpSo0tymV6f9e</a></li>
                        <li> 将文件上传到云端:<a href="`+SERVER+`/api.php#files__path__put">`+SERVER+`/api.php#files__path__put</a></li>
                        <li> 其他方法: 推荐，新闻，书店列表<a href="`+SERVER+`/api.php#">`+SERVER+`/api.php#</a></li>
                    </ol>
                    <p class="sub-title"> 将电子书店连接到PocketBook 云服务的步骤: </p>
                    <div class="sub-step">
                        <p class="title">1.给PocketBook信息(获取授权选项和密钥)。</p>
                        <p> 例如: </p>
                        <p><strong>get_auth_options_url:</strong> http://test-partner.com/pbcloud/get_auth_option</p>
                        <p><strong>secret_key:</strong> 7Y5JhK67G567Yik8</p>
                        <div class="sub-step">
                            <p class="title">1.1 r如何获取授权？</p>
                            <p> 当用户尝试登录Pocketbook时，Pocketbook将发送请求: </p>
                            <pre>
POST http://test-partner.com/pbcloud/get_auth_options
{
    "UserID": "test_user@gmail.com"
}</pre>
                            <p> 响应-这是该用户拥有帐户的合作伙伴商店的列表。<strong> 示例: </strong></p>
                            <pre>
{
    "auth-providers": [
        "name": "Test partner shop 1",
        "clientID": 1,
        "loggedBy": "password|facebook|gmail",
        "icon": "http://test-partner-shop.com/img/logo.png",
        "icon-eink": "http://test-partner-shop.com/img/icon-eink.png",
        "shop_id": 1,
        "login": {
            "native": {
                "url": "http://test-partner-shop.com/pbcloud/user_authenticate"
            }
        },
    ],
    ...
}</pre>
                            <p> 其中“身份验证提供者”-这是商店列表 </p>
                            <p class="title">1.2.用户在给定的商店中选择其一，输入密码，然后Pocketbook将发送下一个请求: </p>
                            <pre>
POST http://test-partner-shop.com/pbcloud/user_authenticate
{
    "login": "test_user@gmail.com",
    "password": "test",
    "serial": "",
    "subpartner": "1",
    "timestamp": "1572867549",
    "signature": "bbae95abe8836dfff47737f56d54357f24125b4f",
}</pre>
                            <p> 预期的响应-这是有关用户的信息。<strong> 示例: </strong></p>
                            <pre>
{
    "user": 123,
    "email": "test_user@gmail.com",
    "firstName": "Test",
    "lastName": "User"
}</pre>
                            <p class="title">1.3.创建签名: </p>
                            <div class="sub-step">
                                <p class ="title">1.3.1。创建这样的字符串: </p>
                                <p>login=test_user@gmail.com&password=test&serial=&subpartner=1&timestamp=1572867549&7Y5JhK67G567Yik8</p>
                                <p> 其中最后一个元素(“7Y5JhK67G567Yik8”)-这是提供者的密钥</p>
                                <p class="title">1.3.2.从该字符串中制作“sha1”哈希 </p>
                            </div>
                        </div>
                    </div>
                </div>
`)

const forDevelopers = {
  'howToConnectStoreToPbCloud': '如何将书店连接到PB云端',
  'section-how-to-connect-store-to-pb-cloud': sectionArticle1
}
export default forDevelopers;