const notifications = {
  noNotifications: '无未读通知',
  spaceAlmostOver: '储存空间不足',
  bookAdded: '上新书籍',
  cannotBeLoaded: '上传空间不足',
  alreadyInCloud: '已存入云端',
  errorHappened: '上传错误',
  formatNotSupported: '不支持此格式',
  licenseCancelled: '许可证已取消',
  licenseExpired: '许可证已过期',
  licenseRevoked: '许可证被撤销',
  withoutLcpToken: '用户没有Lcp代金券',
  incorrectLcpToken: '用户的Lcp密钥不正确',
  errorLoginText: '找不到用户的lcp DRM供应商',
  oldCloud: '如果要使用旧界面，转至设置 → 界面',

  plsLogin: '请使用Lcp帐户登录',
  tryAgain: '再次尝试',
  doneLoginText: '登录成功',
  getMoreSpace: '获得更多空间',
  view: '视图',

  bookmarkAdded: '添加书签',
  bookmarkError: '此位置已添加书签',

  'error303': '已存入云端',
  'error325': '不支持此格式',
  'error311': '未找到文件',

  'error312': '文件为空',
  'error320': '无效文件数据',
  'error321': '无法创建目录或文件',
  'error322': '无法保存文件更改',
  'error324': '文件路径为空或无效',
  'error326': '找不到或不支持mime类型文件',
  'error334': '文件上传空间不足',
  'error335': '文件大小与标题参数’ “Content-Length”无关，或者文件md5哈希值与标题参数“file_md5”无关',
  'error339': '无法解析元数据',
  'error441': '下载网址无效',
  'error442': '文件下载失败',
  'error443': '文件上传到azure云端失败',
  'error450': '未知文件服务错误',
  'error1701': '未找到任务',
  'error1702': '未创建任务',
  'error1703': '未完成任务',
  'error1704': '任务失败',
  'error151': '本书适用于另一个账户',
  'error152': '本书仅适用于一种(其他)设备'
}
export default notifications;