const account = {
  name: 'Имя',
  surname: 'Фамилия',
  loginEmail: 'E-mail',
  bookOfWeek: 'Книга недели',
  personRecom: 'Рекомендации',
  bestsellers: 'Бестселлеры',
  newThisWeek: 'Новинки',
  readReateNews: 'ReadRate новости',
  readRateDisc: 'ReadRate обсуждения',
  nameNotEmpty: 'Имя не может быть пустым',
  nameIncorect: 'Имя неверно',
  emailNotEmpty:'Электронная почта не может быть пуста',
  emailIncorect: 'Неправильный адрес электронной почты',
  surnameNotEmpty: 'Фамилия не может быть пуста',
  surnameIncorect: 'Фамилия неверна',
  resetGoToStore: 'Чтобы сбросить пароль, перейдите в свой магазин:',
  resetPassword: 'Сброс пароля',
  dashboardWidgets: 'Виджеты на главной странице:',
  saveChanges: 'Сохранить изменения',
  changeLanguage: 'Сменить язык',
  en: 'Английский',
  ru: 'Русский',
  de: 'Немецкий',
  zh: 'Китайский',
  findStore: 'Нет учетной записи?'
}
export default account;