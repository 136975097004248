export const policyHeaderText = () => (`
  <h1> 隐私声明 </h1>

  <p> 公司: </p>
  <p>Pocketbook International SA</p>
  <p> 地址: 瑞士，卢加诺Crocicchio Cortogna 6, 6900</p>
  <p><a href="tel:+41919220705">电话: +41 91 922 07 05</a></p>
  <p><a href="tel:+41919220337">传真: +41 91 922 03 37</a></p>
  <p> 公司ID: CHE-416.098.857</p>
  <p> 增值税ID号: CHE-416.098.857 IVA</p>
  <p> 授权代表: Evgenii Zaitsev,销售及und营销经理 </p>
  <p><a href="mailto:privacy@pocketbook-int.com">privacy@pocketbook-int.com</a></p>
`)
export const policyFooterText = () => (`
<p> 2018年5月25日 </p>
`)
export const section1 = () => (`
  <p>(1)本隐私声明旨在解释PocketBook International SA(以下称“PocketBook”)如何在其在线服务以及所有相关网站，功能和内容上收集和处理您的个人数据。</p>
  <p>(2)请仔细阅读《通用数据保护条例》(GDPR)第4条，了解其涉及的术语，例如“个人数据”或其“处理”。</p>
  <p>(3)“用户”是指与数据处理相关的所有人员，包括我们在线服务的业务合作伙伴、客户、兴趣方和其他访问者。使用术语中涉及的，例如“用户”，都是中性的意思。</p>
  <p>(4)在线服务下处理的用户个人数据如下: </p>

  <ul>
    <li> 身份数据包括用户的姓名和地址；</li>
    <li> 联系数据包括电子邮件地址，电话号码；</li>
    <li> 合同数据包括服务使用或产品购买，付款数据；</li>
    <li> 使用数据包括访问在线服务网站记录，对服务和产品的兴趣；</li>
    <li> 内容数据包括在问题表单中的条目；</li>
    <li> 技术数据包括ip地址，设备信息。</li>
  </ul>

  <p>(5)我们出于以下目的处理有关用户的个人数据: </p>

  <ul>
    <li> 提供在线服务，包括内容和功能；</li>
    <li> 根据合同提供服务；</li>
    <li> 提供客户服务；</li>
    <li> 回答问题表格中的问题并与客户沟通；</li>
    <li> 市场营销；</li>
    <li> 确保在线服务的安全性。</li>
  </ul>

  <p>(6)我们严格遵循数据保护规则来处理您的个人数据。这意味着我们仅在适用法律允许的情况下处理有关用户的数据。特别是在处理数据的情况下，如果在线服务也需要这些数据，符合法律要求，获得用户同意，或者基于我们的合法利益，我们可能能够根据合同提供服务(例如，处理请求和订单)，我们的合法利益包括在线服务的分析、优化、安全和商业运营。</p>
 <p>(7)注意，获得用户同意的法律依据是GDRE第六条第一款(a)和第七条。根据合同进行数据处理以提供服务、执行活动的法律依据是GDPR第六条第一款（b）。根据GDRE第六条第一款(c)履行合同所规定的数据处理的义务,根据GDRE第六条第一款(f)，保护数据处理的合法利益</p>
`)
export const section2 = () => (`
  <p>(1)根据GDPR第32条规定并考虑到最新技术，实施成本以及数据处理的性质，范围，背景和目的以及自然人权利和自由的可能性和严重性不同的风险，我们采取并实施合适的技术和组织措施，以确保应对风险的安全级别。因此，我们处理的数据应受到保护，尤其要使数据免受意外或非法破坏，丢失，更改，禁止未经授权披露或访问个人数据。安全措施包括对在浏览器和我们的服务器之间传输个人数据进行加密。</p>
  <p>(2)此外，我们已经制定了程序来删除任何可疑的个人数据以及处理其造成的威胁。</p>
`)
export const section3 = () => (`
  <p>(1)当我们向其他个人和实体(授权处理人或第三方)披露数据，传输数据或在我们的处理框架内授予他们对数据的访问权限时，我们仅根据合法许可执行此操作。根据GDRE第六条第一款（b），将数据传输给第三方时，例如运输服务提供商，如果得到的您同意，法律规定了该义务或对于我们的合法利益是必要的(例如，如果使用授权人员，网络托管人员等)，履行合同是必要的。</p>
 <p>(2)当我们在第三国(欧盟或欧洲经济区之外)共享和处理数据时，或者我们使用第三方服务或向第三方披露或传输数据，我们根据GDRE第44条特定条款来执行。这意味着数据处理要履行例如官方认可的特殊合同义务(即“标准合同条款”)。</p>
 <p>(3)我们信任第三方基于GDRE第28条规定的授权处理人合同进行数据处理。</p>
`)
export const section4 = () => (`
  <p>(1)根据GDRE第六条第一款（f）规定的合法利益,我们可以收集每次访问该服务所在服务器的数据(即服务器日志文件)。这些数据在技术上要求向您显示相关网站并确保稳定性和安全性所。这类访问数据尤其包括网站的名称、文件、服务器请求的时间、传输的数据量、关于访问尝试是否成功的报告、浏览器类型/版本、使用的操作系统、先前访问的网站和ip地址。</p>
  <p>(2)出于安全原因(例如，调查欺诈或滥用行为)，日志文件的信息最多保留30天，然后删除。出于调查目的而保留的数据在最终调查结果出来之前不得删除。</p>
`)
export const section5 = () => (`
   <p>(1)根据GDRE第六条第一款（b），我们处理身份，联系方式和合同数据，以履行合同义务并提供服务。联系表格中标记为必填的条目字段是订立合同所必需的。</p>
  <p>(2)用户可以选择创建一个帐户，在那里他们可以看到他们的订单，或者，如果他们订阅了Pocketbook云服务，就可以控制和使用电子内容(特别是电子书)。在注册时，用户应被告知需要哪些必填数据。当您注销帐户时，如果没有法律要求继续保留，则根据GDRE第六条第一款（c）规定的《商业和税收法规》删除相关的数据。用户有责任确保其数据的安全性，直到合同到期删除数据为止。我们有权永久删除合同期内保留的所有用户数据。</p>
 <p>(3)注册和签署新的账号使用在线服务时，我们存储ip地址和相关用户活动的时间。我们基于合法利益进行存储，以保护用户免受欺诈和其他未经授权的使用。通常，这些数据不会传输给第三方，除非根据GDRE第六条第一款（c）规定，该传输对于我们的索赔是必要的，或者有法律义务要求。</p>
  <p>(4)我们处理用户数据(例如访问在线服务网站记录，对产品的兴趣)和内容数据(例如联系表格的条目)，以用于用户个人资料中的广告目的，向用户展示，例如，基于之前使用的服务的产品信息。</p>
 <p>(5)当法律规定的担保和类似义务到期时，将删除合同中提供服务所需的数据。如果法律规定将数据存档，则应在存档义务到期后将其删除(根据商法，保留数据的义务年限为6年，税法则为10年)。用户帐户下的数据将被保留，直至删除。</p>
`)
export const section6 = () => (`
  <p> 如果您使用联系表或通过电子邮件与我们联系，我们将根据GDRE第六条第一款（b）规定处理您提供的数据，以处理您的查询并继续跟进。</p>
`)
export const section7 = () => (`
  <p> 您可以在我们的在线商店中对产品进行评论。您的评论将与您的姓名以及相关产品一起发布。我们建议您使用化名。姓名和电子邮件地址必填，其余信息选填。当您发布产品评论时，我们会存储您的ip地址，并在30天内将其删除。存储ip地址是为了保护我们免受发布非法内容的责任索赔。如果有第三方举报您的评论涉嫌违法，我们需要您的电子邮件地址与您取得联系。此条款的法律依据是GDRE第六条第一款（b）(f)。评论在发布之前不会进行审核，但如果第三方声称评论是非法的，我们保留删除评论的权利。</p>
`)
export const section8 = () => (`
  <p>(1)下面我们向您解释新闻通讯的内容，订阅和发送程序以及您的拒绝权。订阅时事通讯即表示您同意接收时事通讯并了解其相关程序。</p>
  <p>(2)只有在接收者同意或法律允许的前提下，我们才会通过电子邮件或任何其他电子通知发送带有广告信息的新闻通讯。通讯内容包含有关我们的产品和服务，促销和公司的信息。</p>
 <p>(3)您可以通过双重选择加入程序订阅我们的新闻通讯。订阅新闻通讯后，您将收到一封确认电子邮件，以确认您的订阅。此确认邮件是为了避免订阅地址错误。订阅新闻通讯应记录在册，以证明订阅是根据法律要求完成的，记录内容包括订阅和确认时间以及ip地址。此目的是为了证明订阅并以便之后调查您的个人数据的滥用。此条根据GDRE第六条第一款（f）规定</p>
  <p>(4)要订阅新闻通讯，您只需填写电子邮件地址。我们将存储您的电子邮件地址以发送新闻通讯。此条根据GDRE第六条第一款（a）规定</p>
 <p>(5)您有权随时取消订阅。您可以在每个新闻通讯的末尾找到退订链接。如果您订阅了时事通讯后又取消订阅，您的个人数据将被删除。</p>
`)
export const section9 = () => (`
 <p>(1)我们的在线服务使用cookie技术。Cookies是使用浏览器存储在您的设备上的小型文本文件，并允许某些信息进入。Cookies不能在您的计算机上运行任何程序或携带病毒。cookies使我们的网站变得友好、高效。</p>
   <p>(2)我们使用临时和永久性cookie。关闭浏览器时，临时cookie会自动删除。它们特别包括会话cookie,又称为会话ID，它将浏览器的不同请求归属为公共会话。它们有助于记住您的设备，以便您重复访问我们的网站。当您注销或关闭浏览器时，会话cookie将被删除。在浏览器会话之间，持久性cookie会存储在您的设备上，因此我们会记住您的偏好以及在网站中的活动。在指定的时间段(此处为一天)后，永久性cookie将被删除。您可以随时删除浏览器安全选项中的cookie。</p>
  <p>(3)此外，我们使用cookie来衡量覆盖范围，如以下第10条谷歌分析使用情况所述。</p>
  <p>(4)您可以根据自己的喜好设置浏览器配置，并拒绝例如第三方的cookie或所有cookie。注意，如果您这样做，您可能无法使用我们在线服务的全部功能。</p>
  <p>(5)您可以通过停用网络广告来拒绝使用cookie来测量覆盖范围和显示广告(<a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a>) and additionally on the US website (<a href="http://www.aboutads.info/choices">http://www.aboutads.info/choices</a>)以及美国网站(<a href="http://www.youronlinechoices.com/uk/your-ad-choices/">http://www.youronlinechoices.com/uk/your-ad-choices/</a>).</p>
`)
export const section10 = () => (`
  <p>(1)基于我们的合法权益(即GDRE第六条第一款（f）规定的在线服务的分析、优化和商业运营的权益)，我们可以使用谷歌分析，它是谷歌有限责任公司所提供的一种网络分析服务。如果谷歌使用cookie，Cookie生成的有关您使用在线服务的信息将被传输到Google并存储在美国的服务器上。</p>
  <p>(2)Google代表我们使用此信息来分析您对我们在线服务的使用情况，以便撰写有关网站活动的报告，并向我们提供我们在线服务和互联网使用情况等其他服务。</p>
  <p>(3)我们仅在IP匿名激活时使用谷歌分析。这意味着谷歌将缩短欧盟成员国内或欧洲经济区协议其他成员国内的用户的ip地址。只有在特殊情况下，完整的ip地址才会传输到美国的Google服务器，并在那里缩短。</p>
  <p>(4)您的浏览器传输的ip地址未与其他Google数据合并。用户可以对使用浏览器软件进行适当的设置来防止cookie的存储。此外，用户可以防止谷歌记录cookies生成的在线服务相关的使用数据，并防止其通过下载和安装浏览器插件来处理这些数据: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
  <p>(5)第三方信息: 谷歌都柏林，谷歌爱尔兰有限公司，地址：爱尔兰，都柏林4，巴罗街，戈登大厦<a href="tel:+3531436 1001">传真: +353 (1) 436 1001</a>.服务条款: <a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>，隐私原则的一般概述: <a href="http://www.google.com/intl/de/analytics/learn/privacy.html">http://www.google.com/intl/de/analytics/learn/privacy.html</a>，以及Google的隐私政策: <a href="http://www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</a>.</p>
`)
export const section11 = () => (`
  <p>(1)基于我们对在线服务的分析、优化和商业运营的合法权益，我们使用社交网络Facebook的“Facebook像素”工具，该工具由Facebook爱尔兰有限公司运营，公司地址：都柏林，大运河港，大运河广场4号。</p>
  <p>(2)通过使用Facebook Pixel像素，Facebook允许将使用在线服务的用户定义为显示广告的目标群体(即“Facebook广告”)。因此，我们使用Facebook Pixel像素将由我们打开的Facebook广告仅显示给那些对在线服务感兴趣或显示某些功能(例如，对基于访问网站确定的某些主题或产品的兴趣)感兴趣的Facebook用户，然后将其传输给Facebook(即“自定义受众”)。通过使用Facebook Pixel，我们希望确保Facebook广告符合潜在用户的兴趣，不令人讨厌。此外，使用Facebook Pixel，我们可以通过查看用户继续单击Facebook广告(即“转换”)来跟踪Facebook广告消息的效率，以进行统计和市场研究。</p>
  <p>(3)Facebook根据其数据隐私政策来处理数据。您可以在Facebook的数据隐私政策中找到有关显示Facebook广告的一般信息: <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>.关于Facebook帮助中心中的Facebook像素和功能的具体信息和详细信息: <a href="https://www.facebook.com/business/help/651294705016616">https://www.facebook.com/business/help/651294705016616</a>.</p>
  <p>(4)您可以拒绝使用Facebook Pixel收集数据以及使用其显示的广告。要设置Facebook显示的广告类型，您可以打开由Facebook页面，并按照说明如何设置自定义广告:<a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a>.这些设置是独立于平台的，它们由所有设备(如桌面计算机或移动设备)获取。</p>
`)
export const section12 = () => (`
  <p>(1)用户有权要求并免费获取我们如何处理您个人数据的信息。</p>
  <p>(2)此外，用户有权纠正错误的数据，限制处理和删除其个人数据，并在适用的情况下有权携带数据，在非法数据已被接受的情况下，有权向相关监管机构投诉。</p>
  <p>(3)用户也可以取消其同意，并即刻生效。</p>
`)
export const section13 = () => (`
  <p>(1)如果存储数据不再满足预期目的时，该数据可能会被删除，且删除数据不会与保留义务发生冲突。除非用户数据出于其他合法目的没有被删除，否则处理将受到限制。也就是说，数据被用于其他目的由此被锁定并且不被处理。这应适用于必须保留以符合商业和税收法规的用户数据。</p>
  <p>(2)根据法律要求，根据 § 257(1)商业法规HGB(例如商业和商业文件)，数据保留期应为6年，根据 § 147(1)税收法规AO(例如交易账簿和会计记录)，保留期则为10年。</p>
`)
export const section14 = () => (`
  <p> 法律规定，用户可以随时撤回处理其个人数据的同意。这可能是因为数据处理是出于广告目的。</p>
`)
export const section15 = () => (`
  <p> 用户必须定期查看我们的隐私声明。我们会修改隐私声明，以反映我们数据处理或法规的变化。一旦这些更改需要你方的合作(例如您的同意)或需要任何其他个人通知，我们将立即通知您。</p>
`)

export const cookiesTermsText = (`<h1>Cookie和跟踪技术通知 </h1>
<section>
<p>PocketBook阅读器有限公司的COOKIE和跟踪技术通知
PocketBook International SA (统称为“PocketBook”、“我们”或“我们的”)
网站维护 <a href="https://cloud.pocketbook.digital" target="_blank" rel="noopener noreferrer">https://cloud.pocketbook.digital</a>.
我们使用各种技术来更多地了解人们是如何
使用此服务。您可以从以下信息中了解这些技术以及如何使用
此Cookie和跟踪技术声明是我们隐私声明的一部分。</p>

<p> cookie和其他跟踪技术: 我们如何使用它们 </p>

<p> 像大多数公司一样，我们在服务中使用cookie和其他跟踪技术
(除非另有说明，从此处开始统称为“cookie”)。</p>

<p> cookie是访问网站时在计算机或移动设备上的小型数据文件。
Cookies被在线服务提供商广泛使用去使他们的网站或服务正常运作，
或者更有效地工作，提供报告信息等。</p>

<p> 我们出于多种目使用cookie并增强您的在线服务体验，
例如，记住您的登录状态。</p>

<p> 我们同时使用会话和持久跟踪技术。
跟踪技术，例如cookie，可以是持久的
(即它们会保留在您的计算机上，直到您删除为止)或临时的
(即它们仅持续保留到您关闭浏览器为止)。</p>

<p> 我们还使用第一和第三方cookie。
我们使用和控制第一方cookie提供服务。
第三方cookie由第三方控制，主要用于分析目的。</p>

<p> 我们的服务使用以下类别的cookie: </p>

<p> 严格必要的cookie: 是满足您特别服务和功能的cookie
，例如
网站导航和访问网站的安全区域。
如果没有cookies，本网站的功能可能会受到限制。</p>

<p> 与分析和性能相关的cookie: 我们可能会使用cookie来评估我们服务的性能，
包括利用分析实践部分以改善
服务推荐内容。</p>

<p> 与功能相关的cookie: 例如，我们可能会使用cookie判断，
您以前是否访问过该服务，或者您是否是新访问者
并帮助我们确定您可能最感兴趣的功能。</p>

<p> 与目标相关的cookie: 我们可能会使用cookie来传递内容，
包括与您对我们服务感兴趣相关的广告。</p>

<p> 使用我们的服务，即表示您同意使用cookie和跟踪技术
以及相应的信息处理。你可以通过删除cookie并禁用浏览器中的cookie，随时取消同意</p>

<p> 您的Cookie选择以及如何选择退出 </p>

<p> 您可以选择是否接受使用cookie
以下将解释你如何行使你的权利。</p>

<p> 大多数浏览器初始设置都为接受HTTP cookie。
大多数浏览器上菜单栏的“帮助”功能都将告诉您如何
停止接受新的cookie，如何接收新cookie的通知
以及如何禁用现有cookie。更多
有关HTTP cookie的信息以及如何禁用，
您可以在以下网址查阅信息: <a href="http://www.allaboutcookies.org/manage-cookies" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org/manage-cookies</a></p>

<p> 控制浏览器上的HTML5本地存储取决于你使用哪个浏览器。
具体的浏览器信息请咨询浏览器的网站(通常在“帮助”部分)。</p> 

<p>注意，如果没有HTTP cookie和HTML5本地存储，
您可能无法充分利用
我们的服务或者服务的某些部分可能无法正常运行。</p>

<p> 您还可以在移动设备的“设置”中禁止对移动应用程序的分析。</p>

<p> Pocketbook的服务提供的cookie和跟踪技术 </p>

<p> 下表列出了某些个人cookies
/跟踪解决方案详细信息以及我们使用它们的目的。</p>

<p> 我们对第三方网站及其隐私政策不承担任何责任，因为
这涉及选择退出跟踪活动。
从您那里收集关于我们服务信息的第三方已经发出以下通知
您可以获得有关他们的政策和做法的信息，在某些情况下，
选择退出他们的某些活动，如下所示: </p>

<p> 您会发现我们使用的单个cookie的更多信息以及
我们在下表中使用它们的目的: </p>

<div class="CookiesTerms__table">
  <table>
      <thead>
        <tr>
          <th>cookies</th>
          <th>服务</th>
          <th>目的</th>
          <th>隐私选择</th>
          <th>PocketBook阅读器</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>accounts.google.com</td>
        <td>谷歌分析</td>
        <td>与分析和性能相关</td>
        <td><a href="http://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">http://www.google.com/intl/en/policies/privacy/</a></td>
        <td>是</td>
      </tr>
    </tbody>
  </table>
</div>


<p>更多信息 </p>

<p> 我们可能会不时修改此COOKIE和跟踪技术通知，
例如，如果我们的网站或与cookies有关的规则发生了变化。
我们保留随时修改列表Cookie通知和Cookie的权利
，恕不另行通知。新的Cookie通知
发布后即刻生效。如果您不同意修订后的通知，您应该
更改您的首选项，或考虑停止使用PocketBook。
在更改生效后，若继续使用此服务，
，即表明您同意受修订后的Cookie通知。最新版本的cookie您可以咨询
此网页。
如果您还有其他问题和/或意见，请与我们联系 <a href="mailto:privacy@pocketbook-int.com">privacy@pocketbook-int.com</a></p>
</section>
`)


export const termsText = () => (`
 <section>
        <h2>一、适用范围，使用条款的修订 </h2>
        <p>(1)以下使用条款适用于Pocketbook International SA云服务的使用(以下简称“Pocketbook”)地址：瑞士，卢加诺6900，6，Crocicchio Cortogna。Pocketbook将其云服务提供给电子内容的消费者，特别是电子书的读者(以下简称“用户”)。前提是用户事先在连接到云服务的电子书店中注册，并在云服务主页上进一步登录云服务，从而使用户和PocketBook遵守这些使用条款。</p>
        <p>(2)这些使用条款专门规定了Pocketbook与用户之间的法律关系。</p>
        <p>(3)Pocketbook保留在现有合同关系内随时修改这些使用条款的权利。Pocketbook将在修订生效之前至少30日内通知用户更改事宜。如果用户在收到通知后30天内没有提出异议，并且在异议期期满后仍继续使用此服务，则修正案应被视为已商定并自该期限届满之日起生效。如果有异议，则使用条款将在先前的条件下继续使用。在修改使用条款时，应单独告知用户反对的权利和保持沉默的法律后果。</p>
        <h2>二、PocketBook服务的主题 </h2>
        <p>(1)Pocketbook为其云服务的用户提供了在云端存储，检索，管理和查看电子内容的机会。</p>
        <p>(2)云服务的使用仅限于Pocketbook在其云服务中支持的那些文件类型。</p>
        <p>(3)在所有其他方面，使用选项的对象和范围均来自云服务中提供的功能。</p>
        <p>(4)Pocketbook与登录用户间达成的使用条款对用户是免费的。</p>
       <p>(5)Pocketbook有权随时免费更改所提供的服务，提供免费或收费的新服务以及终止免费提供的服务。在以上每种情况下，Pocketbook都会考虑用户的合法权益。</p>
       <h2> 三、注册。登录。</h2>
       <p>(1)Pocketbook的云服务应在用户在连接到云服务的电子书店中注册并且Pocketbook和用户之间达成使用条款后提供给用户。如果用户使用Pocketbook阅读器或阅读应用程序，Pocketbook的云服务就是其功能之一。在这些情况下，通过PocketBook服务相应的购买或使用过程来确定云服务的登录(包括达成这些使用条款)。如果通过Pocketbook网站访问云服务，则登录受以下第2和第3段的约束。此服务仅允许16岁以上且法律行为能力不受限制的人员登录。没有权利缔结这些使用条款。</p>
        <p>(2)通过Pocketbook网站使用云服务的先决条件是用户已经注册，并且具有连接到云服务的电子书店的帐户。如果没有，用户可以从地图中选择一个电子书店，在登录云服务的过程中，在用户屏幕上会出现 “您还没有帐户？”链接，点开链接，在选定的电子书店中创建一个用户帐户，连接到云服务，然后按照第3段说明通过PocketBook网站登录使用云服务。</p>
        <p>(3)通过PocketBook网站登录使用云服务时，用户必须填写连接到云服务的相应电子书店中提供的注册表。首先必须在连接到云服务的电子书店中输入用户帐户的邮箱地址或用户名。单击“继续”按钮后，Pocketbook将检查指定的邮箱地址是否按访问日期分配给连接到云服务的电子书店。如此，便可以则显示有问题的电子书店，并要求用户使用相应的密码登录。用户可以识别并纠正在相应的输入字段中的任何数据错误。通过单击“登录”按钮，用户提交具有约束力的要约，并在Pocketbook和用户之间达成这些使用条款，从而使用云服务。用户应在登录云服务主页时，与Pocketbook一起遵守这些使用条款。此后，用户提供的数据将由Pocketbook检查，如果Pocketbook认为该数据正确，没有其他问题，Pocketbook会激活用户对其云服务的访问，并通过电子邮件通知他们。此电子邮件被视为Pocketbook的接受声明，并作为Pocketbook和用户之间这些使用条款的确认。合同文本，即特别是用户提供的数据，存储在Pocketbook的内部系统中，在使用条款签订后随时通过登录Pocketbook的云服务对其进行访问。</p>
        <p>(4)合同语言是英语。</p>
        <h2>四、用户责任 </h2>
        <p>(1)用户的访问数据(包括密码)必须保密，未经授权，第三方不得访问。用户有责任确保对Pocketbook的云服务的访问完全由用户本人或其授权的人员进行。如果怀疑未经授权的第三方已经获得或将获得访问数据，用户必须立即通知Pocketbook。在这种情况下，Pocketbook有权阻止其对云服务的访问，直到具体情况被确认为止。</p>
        <p>(2)根据法定规定，用户应对使用数据的任何使用或其他活动负责。</p>
        <p>(3)使用云服务时，用户有义务遵守适用的法律，保护第三方的权利。特别是，用户应确保用户文件和其他内容是合法获取。用户还负责确保其文件和其他内容不含恶意软件、病毒、特洛伊木马、间谍软件、蠕虫或任何其他有害代码。有合法依据证明Pocketbook不会检查用户的文件和内容，也不会受到有害代码等的影响。</p>
        <h2>五、PocketBook的赔偿条款 </h2>
        <p> 若用户未遵守Pocketbook云服务使用关系中的用户义务，特别是本使用条款第四节中的义务，由此造成的任何损害，用户有义务赔偿。此外，针对第三方提出的任何索赔，若是由于用户未遵守此类义务，第三方不可向Pocketbook提出索赔。</p>
        <h2>六、PocketBook的使用权 </h2>
        <p>(1)通过将其电子内容存储在Pocketbook云服务中，用户授予Pocketbook对其内容的免费和可转让的使用权，特别是用于存储，复制和处理，只要这是Pocketbook云服务所必需的。</p>
        <p>(2)，如果用户保存过程中或保存内容本身导致违反使用条款，或者有具体迹象表明将发生严重违规，无需事先通知，Pocketbook保留拒绝保存用户的电子内容的权利和/或编辑、锁定或删除已经保存的内容的权利。Pocketbook将考虑用户的合法权益，选择最合适的方法来避免此类违规行为。</p>
        <h2> 七、期限、终止 </h2>
        <p>(1) Pocketbook的云服务使用条款将永久有效。用户可以终止这些使用条款，且无需书面或电子邮件通知。</p>
        <p>(2)Pocketbook可以在发出通知的14天之内终止使用云服务的使用条款。锁定权不受影响。</p>
        <p>(3)因正当理由而终止的权利不受影响。特别是在以下情况下，PocketBook存在令人信服的理由: </p>
        <p>
            <ol type="a">
                <li> 用户违反了使用条款中所涉及的关于使用Pocketbook云服务的义务，尤其是使用条款的第四条的义务，尽管特别提醒您在规定时限内纠正这种情况。如果提醒不能保证成功，或者违规行为严重到无法期望Pocketbook遵守这些使用条款，则不需要提醒。如果违反了第四条(用户义务)中的义务，则用户必须主要承担严重的违约行为。此外，违规的严重性也可能是由于用户已经被多次警告违规。</li>
                <li>Pocketbook停止提供其云服务或进行其业务活动。</li>
            </ol>
        </p>
        <h2>八、用户发生非法或非合同行为应采取的措施 </h2>
        <p>(1)如果有具体迹象表明用户违反或已经违反了使用条款或适用法律，则Pocketbook可以在不另行通知的情况下采取以下一项或多项终止措施: </p>
        <p>
            <ol type="a">
                <li> 警告用户，如果违规发生一次并且没有对PocketBook造成严重损坏；</li>
                <li> 删除用户的内容；</li>
                <li> 限制用户使用云服务；或 </li>
                <li> 暂时或永久性禁止用户使用云服务(锁定)。</li>
            </ol>
            在Pocketbook采取的以上一项或多项措施时，Pocketbook的任何其他正当利益仍适用。
        </p>
        <p>(2)在选择措施时，Pocketbook还需考虑用户的合法权益。</p>
        <p>(3)如果用户已被Pocketbook锁定，则无权恢复对Pocketbook云服务的访问。用户不得通过其他用户或重新注册来使用Pocketbook云服务。</p>
        <h2> 九.PocketBook责任 </h2>
        <p>(1)Pocketbook的责任包括-无论出于何种法律原因-仅 </p>
        <p>
            <ol type="a">
                <li> PocketBook法定代表人，执行雇员或其他代理人出现故意和重大过失。</li>
                <li> 出现任何有罪责的违反，不履行或不可能履行基本合同义务 (“基本合同义务”一词抽象地指，其义务履行使使用条款首先成为可能，并使另一方可以定期遵守该条款)。</li>
            </ol>
        </p>
        <p>(2)根据第九条第一款履行义务。b)，责任仅限于经济损失和财产损失的情况下通常可预见的损害金额。</p>
        <p>(3)上述责任限制不适用于强制性法定责任的情况，尤其是关于产品责任法，对生命，肢体或健康的担保或有罪责的伤害。</p>
        <p>(4)在由于简单疏忽造成的数据丢失的情况下，如果用户对重要的数据和内容进行了适当和定期的备份，PocketBook仅对由此造成的损害负责;如果由于Pocketbook的责任阻碍或无法进行数据备份，则此限制不适用。</p>
        <p>(5)在不包括或限制Pocketbook责任的范围内，这也适用于其雇员，代表和其他雇员以及代理人的个人责任。</p>
        <h2> 十、最终条款 </h2>
        <p>(1)Pocketbook有权将本使用条款中所涉的权利和义务全部或部分转让给第三方。如果合同方发生变更(即，如果第三方取代了PocketBook承担这些使用条款中所涉的权利和义务)，用户有权终止使用条款，且无需书面或电子邮件通知。本使用条款授予第三方的用户权利转让不包括在内。特别是，用户帐户的转移是不可接受的。</p>
        <p>(2)如果本使用条款的个别规定全部或部分无效，则应保持有效。除无效规定外，其他均应适用有关的法定规定。</p>
        <br>
        <br>
        <p> 上次审查: 3月_，2020</p>
    </section>
`)

const privacy = {
  termsOfUse: '使用条款',
  cookiesConditions: 'cookies术语和条件',
  policyHeaderText: policyHeaderText,
  policyFooterText: policyFooterText,
  privacy01: '目的和法律基础',
  privacy02: '安全措施',
  privacy03: '向第三方和服务提供商传输数据',
  privacy04: '收集访问数据和日志文件',
  privacy05: '根据合同履行服务',
  privacy06: '接触',
  privacy07: '产品评论',
  privacy08: '新闻通讯',
  privacy09: 'Cookies',
  privacy10: '谷歌分析',
  privacy11: 'Facebook营销服务',
  privacy12: '用户权利',
  privacy13: '删除数据',
  privacy14: '撤销权',
  privacy15: '隐私政策更改通知',
  section1: section1,
  section2: section2,
  section3: section3,
  section4: section4,
  section5: section5,
  section6: section6,
  section7: section7,
  section8: section8,
  section9: section9,
  section10: section10,
  section11: section11,
  section12: section12,
  section13: section13,
  section14: section14,
  section15: section15,

  termsOfUseText: termsText,
  cookiesTermsText: cookiesTermsText,
}
export default privacy;