const dashboard = {
  dashboardTitle: '仪表板',
  lastRead: '上次阅读',
  continueReading: '继续阅读',
  continueListening: '继续收听',
  newBookMonth: '本月新书',
  booksInCloud: '云端书籍',
  readingNow: '现在阅读',
  markedRead: '标为已读',
  yourCloudStat: "云数据统计",
  myBooks: '我的书籍',
  mostDiscussed: '讨论最多',
  news: '新闻',
  shareYourReading: '与朋友分享你的阅读进度，讨论最喜欢的书',
  loginToReadRate: '登录到您的ReadRate帐户',
  login: '登录',
  more: '更多',
  justAboutToStart: '即将开始阅读',
  haveNotMarked: '还未将书标记为已读'
}
export default dashboard;
