export const infoText = () => (`
  <div data-ng-bind-html="text" class ="break-word ng-binding">
    创建PocketBook 云服务帐户，请遵循以下说明:
    <br><br>
    1.找到你附近的书店。
    <br>
    2.进入书店的网站，创建一个账户。
    <br>
    3.返回PocketBook云服务，并使用创建的书店的帐户登录。
  </div>
`)

const findStore = {
  infoText: infoText,
  okay: '明白',
  findModalTitle: '找到最近的书店',
  findModalSearch: '名称、地址、zip代码',
  redirect_To_Registration: '立即注册'
}
export default findStore;