import { API, APIUrlencoded, getAuthHeaders } from './axiosInstance'
import { CLIENT_ID, CLIENT_SECRET } from '../constants'
import { getUser, updateUser } from '../utilities'
import { prop } from 'ramda'
import moment from 'moment'
import {getQrCode} from "../utilities";

export const mapProviders = ({ data: { providers, avatar } }) => {
  let pbookde = false,
      index = false;
  for (let i=0;i<providers.length;i++) {
    let provider = providers[i]
    if (provider.alias === "pbookde") {
      pbookde = provider
      index = i
    }
  }
  if (pbookde) {
    providers.splice(index, 1)
    providers.splice(0, 0, pbookde)
  }
  return {
    data: {
      providers: providers.map(provider => ({
        ...provider,
        id: provider.shop_id + provider.alias,
      })),
      avatar: avatar
    }
  }
}

export const loginByUsername = (username, language) =>
  API.get('/auth/login', {
    params: {
      username: username.trim(),
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      language
    },
  }).then(mapProviders)

export const refreshToken = () =>
  APIUrlencoded.post('/auth/renew-token', {
    grant_type: 'refresh_token',
    refresh_token: getUser().refresh_token
  }, getAuthHeaders()).then(({data}) => {
    const updatedCreds = {
      ...data,
      expiresAt: moment().add(prop('expires_in', data), 'seconds')
    }
    updateUser(updatedCreds)
    return getUser()
  })

export const loginToProvider = ({provider, username, ...rest}) => {
  //can be several accounts in different sub-pocket-systems!
  const { shop_id, alias } = provider
  return APIUrlencoded.post(`/auth/login/${alias}`, {
    shop_id,
    username: username.trim(),
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    grant_type: 'password',
    ...rest
  })
}

export const getSNlinks = () => {
  return API.get('/snauth/login-urls').then(res => res.data)
}

export const loginBySN = ({alias, hash, code}) => {
  return API.get(`/snauth/login-callback/${alias}/${hash}`, {
    params: {
      code
    }
  })
}

export const getLcpToken = (fields) => {
  return API.post(`/lcp/user-token`, fields, getAuthHeaders())
}

export const checkLcpToken = () => {
  return API.get(`/lcp/user-token`, getAuthHeaders())
}

export const getQRCode = (session_id) => {
  let qrCode = getQrCode(session_id)
  if (!qrCode) {
    qrCode = API.get(`/auth/qr/${session_id}`).then(res => res.data.data)
  }
  return qrCode
}

