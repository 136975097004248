import adobe1 from '../../assets/images/adobe1.jpg'
import adobe2 from '../../assets/images/adone2.jpg'
import adobe3 from '../../assets/images/adobe3.jpg'
import adobe4 from '../../assets/images/adobe4.jpg'
import {CLOUD_URL} from "../../constants";

export const section1 = () => (`
<h2> 如何注册 </h2>
<p> 您可以通过网站，PocketBook 阅读器移动应用程序(适用于Android和IOS系统)或在PocketBook电子墨水阅读器的帮助下登录PocketBook云服务。</p>
<p><span>Web: </span> 要进入站点，请使用您在会员书店中帐户的登录账号和密码。
此云服务还支持通过Facebook或Google进行授权。如果您使用Facebook或Google在书店注册，则可以通过相同的方式访问云端。
如果您没有帐户，请单击链接“没有帐户”，将出现会员书店的列表。继续选择列表中某一书店，或使用搜索。您将重定向到会员书店网页，然后转到注册页面并按照说明创建一个新帐户，最后再次回到原页面。
</p>
<p><span>PocketBook电子墨水阅读器: </span> 在设备的初始设置过程中，建议您登录PocketBook 云帐户。</p>
<p>如果您选择跳过了此步骤，登录PocketBook 云帐户的建议将出现在通知面板中。要登录云账户，请点击此通知或转至设置> 帐户和同步> PocketBook云服务。您将重定向到登录页面，您必须点击没有帐户链接，然后合作书店列表将在浏览器上打开。单击，选择列表某一书店，您将重定向到合作书店网页。转至登录页面，然后根据说明创建一个新帐户。之后，请返回“设置”>“帐户和同步”>“PocketBook云服务”，输入用于创建合作书店帐户的个人信息，然后点击“登录”按钮。</p>
<p><span>Andriod设备: </span> 要在Android设备上登录，请转到PocketBook 阅读器应用程序，滑到右侧菜单，然后选择PocketBook 云服务。在登录页面上，点击“没有帐户”链接，合作书店列表将在浏览器上打开。单击，选择列表任一书店，您将被重定向到合作书店网页。转至登录页面，然后根据说明创建一个新帐户。之后，请返回PocketBook阅读器应用程序，在右侧菜单上选择PocketBook 云服务，在登录页面上输入用于创建合作书店帐户的个人信息，然后点击登录按钮。</p>
<p>下载适用于Android系统的PocketBook 阅读器应用程序: <a href="https://play.google.com/store/apps/details?id=com.obreey.reader">https://play.google.com/商店/应用/详细信息id=com.obreey.读者</a></p>
<p><span>IOS设备: </span> 要在IOS设备上登录，请转到PocketBook 阅读器应用程序，选择“设置”> PocketBook 云服务。在登录页面上，点击“没有帐户”链接，合作书店列表将在浏览器上打开。继续单击选择列表任一书店，您将被重定向到合作书店网站。转到登录页面，然后根据说明创建一个新帐户。之后，请返回PocketBook阅读器应用程序，在“设置”部分选择PocketBook 云服务，在“登录”页面上输入用于创建合作书店帐户的个人信息，然后点击“登录”按钮。</p>
<p>下载适用于iOS系统的PocketBook阅读器应用程序: <a href="https://itunes.apple.com/ua/app/PocketBook阅读器/id805488884l=ru&mt=8">https://itunes.apple.com/ua/app/PocketBook阅读器/id805488884?l=ru&mt=8</a></p>
        <h2>如何登录</h2> 
        <p>要通过网站登录到您的PocketBook云帐户，请转到网站 <a href="`+CLOUD_URL+`">`+CLOUD_URL+`</a>，填入您的邮箱地址，然后点击“下一步”按钮。选择书店，然后输入密码并点击登录。</p>
        <p>要登录PocketBook电子墨水阅读器，请转到设置> 帐户和同步> PocketBook云服务，然后输入电子邮箱和密码。</p>
        <p>要在Android设备上登录，请转到PocketBook 阅读器应用程序，滑动到右侧菜单，然后选择PocketBook 云服务。输入您的电子邮箱和密码，然后按登录按钮。</p>
        <p>要在IOS设备上登录，请转到PocketBook 阅读器应用程序，选择“设置”> PocketBook 云服务。输入您的电子邮箱和密码，然后点击登录按钮。</p>
        <h2>恢复/更改密码 </h2>
        <p>您可以在书店的网站上恢复密码。使用书店的登录账号和密码登录云服务。</p>
        <p>您可以在书店的网站上更改密码。使用书店的登录账号和密码登录云服务。</p>
        <h2>添加/加入Adobe ID</h2>
        <p><span> 添加Adobe ID: </span></p>
        <p> 要添加Adobe ID，请转到设置/AdobeID，然后单击“添加Adobe ID”按钮。从列表中选择您的经销商，然后输入您的登录账号和密码。
若有多个AdobeID，您可以选择其中一个作为您的主要AdobeID(“默认使用”)。用于将下载电子书到PoxketBook云端。
要查看主要Adobe ID帐户(经销商: PocketBook)的密码，您可以单击“眼睛”图标。</p>
        <p><span> 添加Adobe ID: </span></p>
        <p>要添加多个Adobe帐户，请执行以下操作: </p>
        <p><a href="http://adeactivate.adobe.com/adept/en/JoinAccountLoginForm.jsp" target="_blank">转到Adobe帐户同步页面</a></p>
        <img src="`+adobe1+`" />
        <p> 选择您的Adobe ID，然后点击登录 </p>
        <img src="`+adobe2+`" />
        <p>从列表中选择PocketBook供应商: 请记住，对于多个供应商ID，您只能使用一个电子书供应商 </p>
        <p>输入第二个供应商帐户ID和密码 </p>
        <img src="`+adobe3+`" />
        <p>点击同步帐户按钮。您将可以轻松地在多个供应商设备之间进行内容传输。</p>
        <img src="`+adobe4+`" />
`)
export const section2 = () => (`
<h2> 设备之间如何同步</h2>
 <p> 您可以在不同的设备上阅读书籍，同时所有笔记，书签和阅读进度都将保存。您可以从登录到PocketBook 云帐户的任何设备访问您的书籍。</p>
        <p><span>在web上: </span>登录PocketBook 云帐户和关闭书籍时书籍列表和阅读位置自动同步。</p>
        <p><span>在电子墨水设备上: </span>登录PocketBook云帐户，书籍打开，关闭或折叠时，单击通知面板上的同步图标，书籍列表和阅读位置自动同步。</p>
        <p><span>在Android设备上: </span>登录PocketBook 云帐户和关闭书籍时书籍列表和阅读位置的动同步。您也可以通过单击库中的同步图标来强制执行同步。</p>
        <p><span>在IOS设备上: </span>登录PocketBook 云帐户和关闭书籍时书籍列表和阅读位置自动同步。</p>
        <h2> 如何暂停和更新同步 </h2>
        <p>无法明确暂停同步，但是请注意，如果没有网络连接，同步将被禁用。</p>
        <h2>我可以在没有互联网连接的情况下访问我的云书籍吗 </h2>
        <p>如果设备上没有网络连接，则只能从上次同步开始访问书籍。</p>
        <h2>同步错误 </h2>
        <p>如果在同步过程中发生错误，请检查您的网络连接。如果网络已正确连接，请尝试同步您的书籍，因为服务器可能发生故障。</p>
`)
export const section3 = () => (`
 <h2> 上传新书 </h2>
 <p> 您可以通过网站，PocketBook电子墨水阅读器或Android/IOS PocketBook 阅读器应用程序将新书上传到PocketBook 云端。同步后，所有新书将自动存储在您的PocketBook云帐户中。</p>
        <h2>PocketBook云端支持什么格式 </h2>
        <p>PocketBook 云端支持PocketBook电子墨水阅读器和PocketBook 阅读器移动应用程序书籍格式(PDF(Adobe DRM)，EPUB(Adobe DRM)，DjVu，TXT，FB2，FB2。zip，CHM，html(基本)，CBZ，CBR，с bt，RTF)。</p>
        <h2>同步后书籍是否存储在设备中 </h2>
        <p>是的，如果书籍是从设备上的PB 云端加载的，可以存储在设备中。</p>
        <h2>如何删除文件 </h2>
       <p>要使用PocketBook 云网站删除书籍，请从书籍的下拉菜单中选择“删除”按钮。(要调用下拉菜单-单击三点形式的图标)。</p>
        <p>要想一次删除多本书籍，可以使用Ctrl+鼠标左键选中需要删除的书籍，然后点击顶部菜单栏中的删除图标。</p>
        <p>要使用PocketBook电子阅读器或Android或IOS设备上阅读应用程序从您的帐户中删除书籍，在图书列表中选中需要删除的书籍，然后打开上下文菜单，您将可以选择-“从设备内存中删除”和“从云端删除”。</p>
        <h2>是否可以恢复文件 </h2>
       <p>如果文件存储在PocketBook 云端，并且仅从设备内存中删除，则在下一次同步后它将再次出现。</p>
       <p>如果文件已从云端删除，则无法还原。</p>
        <h2>如何从云端下载书籍 </h2>
        <p>要将书籍下载到电子墨水阅读器，Android或IOS设备的内存中，只需从库中的书籍列表中点击云端书籍，下载将自动开始。</p>
        <p>若要将一本书从PocketBook 云端下载到计算机上，请从书籍的下拉菜单中选择“下载”。(要调用下拉菜单-单击三点形式的图标)。</p>
        <p>要同时下载多本书，可以使用Ctrl+鼠标左键选中所需下载的书籍，然后点击菜单栏顶行的下载图标。</p>
        <h2>如何在浏览器阅读书籍 </h2>
        <p>目前，您可以在浏览器中阅读EPUB和PDF格式的书籍。</p>
        <p>要在浏览器中打开一本书，请单击书的封面(在“图块”模式下)，或带有书名的行上(在“列表”模式下)。在新窗口中，这本书将在网络阅读器中打开。您也可以从“有关书籍的信息”下拉菜单中打开书籍。</p>
         <p>从浏览器阅读也会实现同步，您的阅读位置，书签和笔记都将被保存。若要退出书籍阅读，请单击应用程序窗口左上角的“退出”图标。</p>
`)

const faq = {
  account: '帐户',
  sync: '同步',
  storage: '文件存储',
  section1: section1,
  section2: section2,
  section3: section3
}
export default faq;