const login = {
  login: '登录',
  loginWith: '登录方式',
  useQRCode: '手机扫描二维码登陆',
  useEmail: '或电子邮箱登录',
  emailPlaseHolder: '输入您的电子邮箱或用户名',
  FAQ_btn: '常见问题解答',
  privacyPolicy_btn: '隐私政策',
  privacyPolicy: '隐私政策',
  impressum_btn: '联系人',
  contact_us_btn: '联系我们',
  login_btn: '登录',
  next_btn: '下一步',
  passwordIncorect: '密码不正确',
  emailIncorect: '电子邮箱不正确',
  searchProviders: '搜索提供商',
  loggingIn: '登录',
  syncAllDevices: '所有设备和平台同步',
  readAnyFormats: '支持ePub、PDF、fb2便携式网络阅读器',
  allYourLibrary: '所有图书库都安全保存',
  chooseBookstore: '选择您的书店',
  iAgreeTo: '我同意 ',
  termsOfUse: '使用条款',
  andWith: ' 和  ',
  goBack_btn: '返回键',
  enterPassword: '输入密码',
  savePasswordNextTime: ' 保存密码',
  googleBtn: '谷歌登录',
  facebookBtn: 'facebook登录',

  legal: '法律通知',
  about: '关于云服务',
  for_developers_btn: '开发人员’按键'
}
export default login;